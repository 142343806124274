import MaintenanceService from "@/services/modules/MaintenanceService";
import router from "@/router";

const maintenanceService = new MaintenanceService();

const url = "super-admin/maintenance";

export default {
  namespaced: true,
  state: {
    maintenance: [],
    formPayload: {
      start_datetime: "",
      end_datetime: "",
      description: "",
      is_active: true,
    },
  },
  getters: {},
  mutations: {
    SET_MAINTENANCE(state, maintenance) {
      state.maintenance = maintenance;
    },
    SET_FORM_PAYLOAD(state, formPayload) {
      state.formPayload = formPayload;
    },
  },
  actions: {
    async getData({ commit }, { params }) {
      const response = await maintenanceService.getData(url, params);
      if (response) {
        commit("SET_MAINTENANCE", response.data);
      }
    },
    async showData({ commit }, { id, params }) {
      const response = await maintenanceService.showData(url, id, params);
      if (response) {
        commit("SET_MAINTENANCE", response.data.data);
      }
    },
    async postData({ commit }, { id, payload }) {
      const response = await maintenanceService.postData(`${url}/${id}`, payload);

      return response;
    },
    async deleteData({ commit }, { id }) {
      const response = await maintenanceService.deleteData(url, id);

      return response;
    },
  },
};
