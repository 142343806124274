export default [
  {
    path: '/master/gudang',
    name: 'master-gudang.index',
    component: () => import('@/views/master/gudang/index.vue'),
    meta: {
      auth: true,
      navActiveLink: 'master.product.index',
    },
  },
  {
    path: '/master/admin',
    name: 'master-admin.index',
    component: () => import('@/views/master/admin-user/index.vue'),
    meta: {
      auth: true,
    },
  },
  {
    path: '/master/cabang',
    name: 'master-cabang.index',
    component: () => import('@/views/master/cabang/index.vue'),
    meta: {
      auth: true,
      navActiveLink: 'master.product.index',
    },
  },
  {
    path: '/master/brand',
    name: 'master-brand.index',
    component: () => import('@/views/master/brand/index.vue'),
    meta: {
      auth: true,
      navActiveLink: 'master.product.index',
    },
  },
  {
    path: '/master/barang',
    name: 'master-barang.index',
    component: () => import('@/views/master/barang/index.vue'),
    meta: {
      auth: true,
      navActiveLink: 'master.product.index',
    },
  },
  {
    path: '/master/satuan',
    name: 'master-satuan.index',
    component: () => import('@/views/master/satuan/index.vue'),
    meta: {
      auth: true,
      navActiveLink: 'master.product.index',
    },
  },
  {
    path: '/master/maintenance',
    name: 'master-maintenance.index',
    component: () => import('@/views/master/maintenance/index.vue'),
    meta: {
      auth: true,
    },
  },
  {
    path: '/master/bank',
    name: 'master-bank.index',
    component: () => import('@/views/master/bank/index.vue'),
    meta: {
      auth: true,
    },
  },
  {
    path: '/master/produk',
    name: 'master.product.index',
    component: () => import('@/views/master/product/index.vue'),
    meta: {
      auth: true,
      navActiveLink: 'master.product.index',
    },
  },
  {
    path: '/master/produk/bulk-link',
    name: 'master.product.bulk-link',
    component: () => import('@/views/master/product/bulk-link.vue'),
    meta: {
      auth: true,
      layout: 'full',
      navActiveLink: 'master.product.index',
    },
  },
  {
    path: '/master/produk/:id',
    name: 'master.product.show',
    component: () => import('@/views/master/product/show.vue'),
    meta: {
      auth: true,
      layout: 'full',
      navActiveLink: 'master.product.index',
    },
  },
]
