import $http from "@axios";

export default {
  namespaced: true,
  state: {
    formPayload: {
      renewal_name: "",
      renewal_description: "",
      renewal_duration_months: "",
      price: "",
      sell_price: "",
      discount_percent: "",
    },
  },
  getters: {},
  mutations: {
    setFormPayload(state, value) {
      state.formPayload = value;
    },
  },
  actions: {
    getData(context, { params, uuid }) {
      return new Promise((resolve, reject) => {
        $http
          .get(`/super-admin/subscription-renewal/${uuid}`, { params })
          .then((result) => {
            resolve(result);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    postData(context, { payload, params }) {
      return new Promise((resolve, reject) => {
        $http
          .post(`/super-admin/subscription-renewal${params || ""}`, payload)
          .then((result) => {
            resolve(result);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
};
